.nutrition-table-container {
    border: 1px solid #999;
    padding: 5px;
    display: inline-block;
    background: white;
  }
  
  .nutrition-table {
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
    padding: 5px 0px;
    background-color: white;
  
    thead {
      tr {
        font-weight: bold;
  
        th {
          font-size: 17px;
          border-top: none;
          padding-bottom: -2px;
        }
        td {
          font-size: 12px;
          border-bottom: 2px solid black;
        }
        td:nth-of-type(2) {
            text-align: center;
          }
          td:nth-of-type(3) {
            text-align: right;
          }
      }
    }
  
    tbody {
      tr:first-child {
        td, th {
          border-top: none;
        }
      }
      tr {
        td:first-child {
          padding-right:10px;
        }
        td, th {
          border-top: 1px solid black;
          span.calories {
            font-weight: bold;
            font-size: 13px;
            border-bottom: 3px solid currentColor;
          }
        }
        td:nth-of-type(3) {
            text-align: center;
          }
        td:nth-of-type(2n) {
          text-align: right;
        }
        td.indent-1, th.indent-1 {
          padding-left: 1em;
        }
        td.indent-2, th.indent-2 {
          padding-left: 2em;
        }
        td.no-overline {
          border-top: none;
        }
        td.percent-daily {
          font-weight: bold;
          font-size: 8px;
          padding-top: 4px;
        }
      }
    }
  
  }
  