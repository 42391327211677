@font-face {
  font-family: 'Bitter';
  src: url('../../fonts/bitter/Bitter-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Bitter';
  src: url('../../fonts/bitter/Bitter-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Bitter';
  src: url('../../fonts/bitter/Bitter-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../../fonts/montserrat/Montserrat-Light.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../../fonts/montserrat/Montserrat-Bold.ttf') format('truetype');
  font-weight: Bold;
  font-style: normal;
}

@font-face {
    font-family: 'sreda';
    src: url('../../fonts/sreda-webfont.eot');
    src: url('../../fonts/sreda-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/sreda-webfont.woff2') format('woff2'),
         url('../../fonts/sreda-webfont.woff') format('woff'),
         url('../../fonts/sreda-desktop.ttf') format('truetype'),
         url('../../fonts/sreda-webfont.svg#sredaregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Aleo';
    src: url('../../fonts/aleo-regular-webfont.eot');
    src: url('../../fonts/aleo-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/aleo-regular-webfont.woff2') format('woff2'),
         url('../../fonts/aleo-regular-webfont.woff') format('woff'),
         url('../../fonts/aleo-regular-webfont.ttf') format('truetype'),
         url('../../fonts/aleo-regular-webfont.svg#aleoregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Aleo';
    src: url('../../fonts/aleo-bold-webfont.eot');
    src: url('../../fonts/aleo-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/aleo-bold-webfont.woff2') format('woff2'),
         url('../../fonts/aleo-bold-webfont.woff') format('woff'),
         url('../../fonts/aleo-bold-webfont.ttf') format('truetype'),
         url('../../fonts/aleo-bold-webfont.svg#aleobold') format('svg');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Aleo';
    src: url('../../fonts/aleo-italic-webfont.eot');
    src: url('../../fonts/aleo-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/aleo-italic-webfont.woff2') format('woff2'),
         url('../../fonts/aleo-italic-webfont.woff') format('woff'),
         url('../../fonts/aleo-italic-webfont.ttf') format('truetype'),
         url('../../fonts/aleo-italic-webfont.svg#aleoitalic') format('svg');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Aleo';
    src: url('../../fonts/aleo-bolditalic-webfont.eot');
    src: url('../../fonts/aleo-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/aleo-bolditalic-webfont.woff2') format('woff2'),
         url('../../fonts/aleo-bolditalic-webfont.woff') format('woff'),
         url('../../fonts/aleo-bolditalic-webfont.ttf') format('truetype'),
         url('../../fonts/aleo-bolditalic-webfont.svg#aleobolditalic') format('svg');
    font-weight: bold;
    font-style: italic;
}
@font-face {
    font-family: 'Aleo Light';
    src: url('../../fonts/aleo-light-webfont.eot');
    src: url('../../fonts/aleo-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/aleo-light-webfont.woff2') format('woff2'),
         url('../../fonts/aleo-light-webfont.woff') format('woff'),
         url('../../fonts/aleo-light-webfont.ttf') format('truetype'),
         url('../../fonts/aleo-light-webfont.svg#aleolight') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Aleo Light';
    src: url('../../fonts/aleo-lightitalic-webfont.eot');
    src: url('../../fonts/aleo-lightitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/aleo-lightitalic-webfont.woff2') format('woff2'),
         url('../../fonts/aleo-lightitalic-webfont.woff') format('woff'),
         url('../../fonts/aleo-lightitalic-webfont.ttf') format('truetype'),
         url('../../fonts/aleo-lightitalic-webfont.svg#aleolightitalic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
  font-family: 'icomoon';
  src:  url('../..//fonts/icomoon/fonts/icomoon.eot?gbhjw5');
  src:  url('../..//fonts/icomoon/fonts/icomoon.eot?gbhjw5#iefix') format('embedded-opentype'),
    url('../..//fonts/icomoon/fonts/icomoon.ttf?gbhjw5') format('truetype'),
    url('../..//fonts/icomoon/fonts/icomoon.woff?gbhjw5') format('woff'),
    url('../..//fonts/icomoon/fonts/icomoon.svg?gbhjw5#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-mc"], [class*=" icon-mc"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    vertical-align: middle;
    padding: 1px 0;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

.icon-mc-compare:before {
  content: "\e900";
}
.icon-mc-sign:before {
  content: "\e901";
}
.icon-mc-verify:before {
  content: "\e902";
}
.icon-mc-delivery:before {
  content: "\e903";
}

@font-face{
font-family: 'Roboto';
src: local('Roboto'), local('Roboto-Regular'), url('../../fonts/roboto/Roboto-Regular.ttf') format('truetype');
font-weight: normal;
font-style: normal;
}

@font-face{
font-family: 'Roboto';
src: local('Roboto Bold'), local('Roboto-Bold'), url('../../fonts/roboto/Roboto-Bold.ttf') format('truetype');
font-weight: bold;
font-style: normal;
}

@font-face{
font-family: 'Roboto';
src: local('Roboto Italic'), local('Roboto-Italic'), url('../../fonts/roboto/Roboto-Italic.ttf') format('truetype');
font-weight: normal;
font-style: italic;
}

@font-face{
font-family: 'Roboto';
src: local('Roboto BoldItalic'), local('Roboto-BoldItalic'), url('../../fonts/roboto/Roboto-BoldItalic.ttf') format('truetype');
font-weight: bold;
font-style: italic;
}

@font-face{
font-family: 'Roboto';
src: local('Roboto Light'), local('Roboto-Light'), url('../../fonts/roboto/Roboto-Light.ttf') format('truetype');
font-weight: 300;
font-style: normal;
}

@font-face{
font-family: 'Roboto';
src: local('Roboto LightItalic'), local('Roboto-LightItalic'), url('../../fonts/roboto/Roboto-LightItalic.ttf') format('truetype');
font-weight: 300;
font-style: italic;
}

@font-face{
font-family: 'Roboto';
src: local('Roboto Medium'), local('Roboto-Medium'), url('../../fonts/roboto/Roboto-Medium.ttf') format('truetype');
font-weight: 500;
font-style: normal;
}

@font-face{
font-family: 'Roboto';
src: local('Roboto MediumItalic'), local('Roboto-MediumItalic'), url('../../fonts/roboto/Roboto-MediumItalic.ttf') format('truetype');
font-weight: 500;
font-style: italic;
}