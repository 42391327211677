.recipe-holder .inner-text img,
.blog-post img {
    display: inline;
    float: right;
    margin-left: 1.625em;
    margin-bottom: 1.625em;
    border: 1px solid #ddd;
    padding: 6px;
    max-width: 100%;
    height: auto;

    @include media-breakpoint-down(sm) {
        float: none!important;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
}