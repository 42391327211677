/* React Modal */

.ReactModal__Portal {
    // NOTE: loading is 9999. Believe bs is 1000?
    // This needs to appear above everything else but beneath .loading's modal.
    z-index: 9995 !important;
  }
  .ReactModal__Overlay {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    z-index: 9994 !important;
  }
  .ReactModal__Content {
    // Override inline style from React Modal.
    position: relative!important;
    display: flex;
    flex-direction: column;
    overflow: hidden!important;
    top: 40px !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    margin: 0 auto;
    max-width: 600px !important;
    max-height: calc(100vh - 80px);
    width: calc(100vw - 80px);
    .slick-slider {
      .slick-arrow:hover:before { color: black; }
      .slick-arrow:before {
        color: $brand-primary;
      }
      .slick-prev, .slick-next {
        height: 40px;
        width: 40px;
      }
    }
  }
  
  
  @media (max-width: 540px) {
    .ReactModal__Content {
      border: 0 !important;
      top: 0 !important;
      bottom: 0 !important;
      left: 0 !important;
      right: 0 !important;
      width: 100% !important;
    }
  }
  
  .ReactModal__Overlay--before-close {
    pointer-events: none !important;
  }
  
  .slot-list {
   overflow-y: auto;
   margin: 0 -15px;
   // HACK: The disabled class is added to the list-group-item and thus overrides the color
   // and background color. We force the values here.
   .list-group-item.list-group-item-danger {
     color: #a94442 !important;
     background-color:#f2dede !important;
   }
   .list-group-item:not(.disabled) {
     &:hover { cursor:pointer }
   }
  }
  
  .no-slots-available {
      display:inline-block;
      position: relative;
      background-image: url(../../img/line_SW_NE.png)!important;
      background-size:100% 100%!important;
      &:hover {background-position: unset;}
  }