.btn-default {
    font-weight: 600;
    color: #333;
    background: #fff;
    border-color: #ccc;
    border-radius: .2rem;
    &:hover {
        color: #333;
        background: #{shade-color(#fff, 10%)};
        border-color: #{shade-color(#ccc, 10%)};
    }
    &:active {
        color: var(--bs-btn-hover-color);
        background: #{shade-color(#fff, 20%)};
        border-color: #{shade-color(#ccc, 20%)};
    }
  }

.btn-grey-400 {
  color: #333;
  background: var(--bs-gray-400);
  border-color: var(--bs-gray-600);
  border-radius: .2rem;
  &:hover {
      color: #333;
      background: var(--bs-gray-500);
      border-color: var(--bs-gray-700);
  }
  &:active {
      color: var(--bs-btn-hover-color);
      background: var(--bs-gray-600);
      border-color: var(--bs-gray-800);
  }
}

.btn-grey-200 {
  color: #333;
  background: var(--bs-gray-200);
  border-color: var(--bs-gray-400);
  border-radius: .2rem;
  &:hover {
      color: #333;
      background: var(--bs-gray-300);
      border-color: var(--bs-gray-500);
  }
  &:active {
      color: var(--bs-btn-hover-color);
      background: var(--bs-gray-400);
      border-color: var(--bs-gray-600);
  }
}

  .btn-twitter {
    background-color: #1DA1F2;
    color: white;
    font-size: 1.5em;
    width: 50px;
    height: 50px;
    &:active,
    &:hover {
        color: black;
        background: #1DA1F2;
        border-color: #1DA1F2;
    }
  }

  .btn-facebook {
    background-color: #4267B2;
    color: white;
    font-size: 1.5em;
    width: 50px;
    height: 50px;
    &:active,
    &:hover {
        color: black;
        background: #4267B2;
        border-color: #4267B2;
    }
  }

$all-colors: map-merge-multiple($blues, $indigos, $purples, $pinks, $reds, $oranges, $yellows, $greens, $teals, $cyans);

@each $name, $value in $all-colors {
  .btn-mc-#{$name} {
    --bs-btn-bg: #{$value};
    --bs-btn-hover-color: var(--bs-white);
    --bs-btn-hover-bg: #{shade-color($value, 10%)};
    --bs-btn-active-color: var(--bs-btn-hover-color);
    --bs-btn-active-bg: #{shade-color($value, 20%)};
  }
}