// ALL VARIABLES MUST HAVE THE !default SUFFIX OR THEY WON'T BE ABLE
// TO BE OVERRIDDEN BY THE CLIENT STYLES

// Bootstrap Color Overrides
$brand-primary: #4a7b6f!default;
$brand-primary-light: lighten($brand-primary, 15%)!default;
$brand-primary-dark: darken($brand-primary, 15%)!default;
$primary: $brand-primary!default;


// Fontawesome Static Path
//$fa-font-path: "../vendors/fontawesome/webfonts";
$font-family-sans-serif:  "Montserrat", Arial, sans-serif!default;
$font-family-serif:       "Bitter", Times, serif!default;

// Core Layout Heights
$mc-header-above-height: 32px!default;
$mc-header-height: 160px!default;
$mc-header-height-mobile: 90px!default;
$mc-header-height-scroll: 50px!default;

$mc-header-nav-height: 42px!default;
$mc-footer-height: 200px!default;
$mc-footer-below-height: 32px!default;

$navbrand-logo-height-small: 120px!default;

// Calculated Layout Heights
$mc-main-content-min-height: calc(100vh - $mc-header-above-height - $mc-header-height - $mc-header-nav-height - $mc-footer-height)!default;
$mc-header-full-height: calc($mc-header-height + $mc-header-nav-height)!default;
$mc-header-full-height-mobile: calc($mc-header-height-mobile + $mc-header-nav-height)!default;
$mc-header-full-height-scroll: calc($mc-header-height-scroll + $mc-header-nav-height)!default;

$mc-footer-main-height: calc($mc-footer-height - $mc-footer-below-height)!default;

// Header Variables
$mc-header-nav-bg: $brand-primary!default;
$mc-header-nav-highlight: $brand-primary-light!default;
$mc-header-nav-border: 5px solid $mc-header-nav-highlight;
$mc-header-full-bg: white!default;


// Footer Variables
$mc-footer-bg: #31353a!default;

// Fave Variables
$base-heart-color: var(--bs-gray-500)!default;
$heart-red: #d9534f!default;
$heart-active-color: $heart-red!default;



$brand-font: "Montserrat", Arial, sans-serif!default;

// Product Thumbnails
$product-thumbnail-height: 400px!default;
$product-thumbnail-title-size: 14px!default;
$product-thumbnail-title-color: #000!default;
$product-thumbnail-unit-color: #666b73!default;
$product-thumbnail-price-size: 20px!default;
$product-thumbnail-current-price-weight: 800!default;
$product-attribute-image-size: 55px!default;


$header-transition: margin-top 0.3s ease-out!default;


$on-sale-color: #d9534f!default;

// Backgrounds
$body-bg-color: #fff!default;
$body-bg-image: unset!default;
$mc-main-content-bg-color: white!default;

$mc-header-nav-full-bg-color: none!default;


