
.address-panel {
    display: inline-block;
    vertical-align:top;
    min-width: 280px;
    width: 100%;
    font-size: 0.8em!important;
    margin-right: 2px;
    margin-bottom: 4px;
}

.address-selector {
    width: auto;
}

.address-panel input, .address-panel select{
    font-size: 1.2em!important;
}

.address-panel .panel-heading { padding: 2px; min-height: 39px; }
.address-panel .panel-footer { padding: 8px; }
.address-panel .panel-footer .btn { border-radius: 0; }

.address-title {
    font-size: 1.5em;
    font-weight: bold;
    top: 6px;
    position: relative;
    padding-left: 10px;
}

.address-table input, .address-table select {
    border-radius: 0!important;
}

.address-field td:not(:first-child){
    padding: 1px 0;
}

.address-field td input[type=checkbox] {
    width: 32px;
    height: 32px;
    margin-left: 0;
}

.address-field .form-group {
    position: relative;
}

.address-fieldlabel {
    font-weight: bold;
}

.address-fielderror {
    margin-left: 1em;
}

.address-has-error input, .address-has-error select {
    border-color: #a94442!important;
    // -webkit-box-shadow: inset 0px -1px 8px rgba(169, 68, 63, 0.5)!important;
    // box-shadow: inset 0px -1px 8px rgba(169, 68, 63, 0.5)!important;
}


.new-address-form {
    > div {
        margin-bottom: 0!important;
        width: 50%;
        padding: 3px;
    }

    @include media-breakpoint-down(sm)  {
        > div {
            width: 100%;
        }
    }

    #div_id_state,
    #div_id_country {
        width: 100%;
    }
}
