.recipe-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;

    .recipe-holder {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        .recipe-box {
            -webkit-box-flex: 1;
                -ms-flex-positive: 1;
                    flex-grow: 1;
            -ms-flex-negative: 1;
                flex-shrink: 1;
            -ms-flex-preferred-size: auto;
                flex-basis: auto;
            @supports not ((display: -webkit-flex) or
                           (display: -moz-flex) or
                           (display: flex)) {
            
                height: 250px; /* fallback for no-flex */
            }
        }
    }
}
