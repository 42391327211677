.top-right-heart {
    color: $link-color;
    font-weight: normal;
    border-radius: 0;
  
    &,
    &:active,
    &.active,
    &[disabled],
    fieldset[disabled] & {
      background-color: transparent;
    }
    &,
    &:hover,
    &:focus,
    &:active {
      border-color: transparent;
    }
    &:hover,
    &:focus {
      color: $link-hover-color;
      text-decoration: $link-hover-decoration;
      background-color: transparent;
    }
    &[disabled],
    fieldset[disabled] & {
      &:hover,
      &:focus {
        color: $btn-link-disabled-color;
        text-decoration: none;
      }
    }
    position: absolute;
    top: 0;
    right: 0;
    z-index: 990;
}

.btn-favourite {
  border: 0;
}

.btn-favourite-product i, .btn-favourite-product:hover i {
    color: $base-heart-color;
}
.btn-favourite-product.has-icons:hover i:after {
    color: var(--bs-gray-300);
    content: "\f067";
    font-size: 0.5em;
    margin-left: -0.75em;
}

.btn-unfavourite-product i, .btn-unfavourite-product:hover i {
    color: $heart-active-color;
}
.btn-unfavourite-product.has-icons:hover i:after {
    color: var(--bs-gray-300);
    content: "\f00d";
    font-size: 0.5em;
    margin-left: -0.75em;
}
.prod-thumb-fave-form {
    display: inline-block;
}