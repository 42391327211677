$facet-heading-bg-color: #fff;
$facet-heading-font-color: #000;

.facet-group {
    a {
        color: $facet-heading-font-color;
        &.facet-header {
            font-size: 1.2em;
        }
        text-decoration: none;
    }
    a.collapsed .arrow-after::after {
        font: var(--fa-font-solid);
        content: '\f107';
        display: inline-block;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
    }
    a .arrow-after::after {
        font: var(--fa-font-solid);
        content: '\f106';
        display: inline-block;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
    }
    .facet-heading {
        padding: 5px;
        border: 0;
        background-color: $facet-heading-bg-color;
      }
}



/* Range Slider */

.fancy-range {
    box-sizing: border-box;
    appearance: none;
    width: 100%;
    margin: 0;
    padding: 0 2px;
    /* Add some L/R padding to ensure box shadow of handle is shown */
    overflow: hidden;
    border: 0;
    border-radius: 1px;
    outline: none;
    background: linear-gradient(grey, grey) no-repeat center;
    /* Use a linear gradient to generate only the 2px height background */
    background-size: 100% 2px;
    pointer-events: none;
 
    &:active,
    &:focus {
       outline: none;
    }
 
    &::-webkit-slider-thumb {
       height: 28px;
       width: 28px;
       border-radius: 28px;
       background-color: #fff;
       position: relative;
       margin: 5px 0;
       /* Add some margin to ensure box shadow is shown */
       cursor: pointer;
       appearance: none;
       pointer-events: all;
       box-shadow: 0 1px 4px 0.5px rgba(0, 0, 0, 0.25);
       &::before {
             content: ' ';
             display: block;
             position: absolute;
             top: 13px;
             left: 100%;
             width: 2000px;
             height: 2px;
       }
    }
 }
 
 .multi-range {
    position: relative;
    height: 36px;
 
    input[type=range] {
       position: absolute;
 
       &:nth-child(1) {
          &::-webkit-slider-thumb::before {
             background-color: purple;
          }
       }
 
       &:nth-child(2) {
          background: none;
 
          &::-webkit-slider-thumb::before {
              background-color: grey;
          }
       }
    }
 }