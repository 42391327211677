.product-thumbnail {  
  min-height: 320px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 4px;

  position: relative;
}

.product-thumbnail:hover {
  box-shadow: 0 0 20px rgb(0 0 0 / 20%);
}

@media (hover: none) {
  .product-thumbnail-hover {
      display: flex!important;
  }
}

.product-thumbnail-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: $zindex-dropdown;

}
.product-thumbnail-attributes {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.on-sale {
  color: #d9534f;
}


.product-thumbnail-hover {
  display: none;
  position: absolute;
  right: 5px;
  left: 5px;
  justify-content: space-between;
  top: 174px;
  z-index: $zindex-dropdown;
  pointer-events: none;
  button {
    pointer-events: all;
  }
}
.product-thumbnail:hover .product-thumbnail-hover {
  display: flex;
}

.product-thumbnail-image { 
  height: 200px;
  position: relative;
}
.product-thumbnail-image img {
  max-height: 200px;
}

.product-thumbnail-name {  
  a {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-weight: 700;
    font-size: $product-thumbnail-title-size;
    color: $product-thumbnail-title-color;
    text-decoration: none;
  }

}

.product-thumbnail-brand { 
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-weight: 800;
  color: #333;
}

.product-thumbnail-title { 

}

.product-sale-badge {
  position: absolute;
  left: 0;
  top: 0;
}

.product-attribute-image {
  height: $product-attribute-image-size;
  width: $product-attribute-image-size!important; //stupid owl carousel overrides this

  @include media-breakpoint-down(sm) {
    height: calc($product-attribute-image-size * 0.75);
    width: calc($product-attribute-image-size * 0.75)!important;
  }
}

.product-thumbnail-description { 
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.product-thumbnail-buttons {  
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin-top: 5px;
  margin-bottom: 5px;
}

.product-thumbnail-price { 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.product-thumbnail-add-buttons { 

}

.product-thumbnail-unit {
  font-size: smaller;
  color: $product-thumbnail-unit-color;
}

.product-thumbnail-add-list { 
  position: absolute;
  bottom: 0;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.product-thumbnail-original {
  text-decoration: line-through;
  font-size: smaller;
}
.product-thumbnail-current {
  font-size: $product-thumbnail-price-size;
  font-weight: $product-thumbnail-current-price-weight;
}

.sale-block,
.cart-sale-callout {
  transform: rotate(-30deg);
  background-color: $on-sale-color;
  color: white;
  font-weight: 700;
  font-size: smaller;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  line-height: 1;
}

.cart-sale-callout {
  display: none;
}
