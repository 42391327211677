// Override any bootstrap or core/_variables.scss variables here.
// eg $brand-primary

$black: #000;
$dark-gray: #31353a;
$white: #fff;
$whitesmoke: #EAEAEA;
$dark-blue: #606C5D;
$light-blue: #8a9a85;
$yellow: #fdb91a;
$brand-secondary: white;
$brand-primary-light: $light-blue;
$brand-primary: $dark-blue;

$font-family-base: Arial, Helvetica, sans-serif;